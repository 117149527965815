import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QuotesColumns from "../../../components/CustomTable/Columns/QuotesColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useAuth } from "../../../context/AuthContext";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useServices from "../../../hooks/useServices";
import { mainPermissions } from "../../../util/MenuLinks";
import useUserQuotes from "../../../hooks/useUserQuotes";
import useOrderStatuses from "../../../hooks/useOrderStatuses";

const MyQuotes = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        id: '',
        name: '',
        serviceIds: '',
        quoteStatusCode: '',
        start: '',
        end: ''
    });

    const [servicesFilters, setServicesFilters] = useState({
        currentUserServices: true
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: { ...servicesFilters } }, { useCache: false });

    const [{ orderStatuses }, getOrderStatuses] = useOrderStatuses({ options: { useCache: false } });

    const [{ quotes, total, numberOfPages, error: quotesError, loading }, getQuotes] = useUserQuotes({ params: { ...filters }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        getQuotes();
    }, [])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando registros'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }

        if (quotesError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los registros.',
                show: true
            });
        }
    }, [deleteError, quotesError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(quotes?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteRecord({ url: `/quotes/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'El registro ha sido eliminado exitosamente.',
                show: true
            });
            getQuotes();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                page: page
            }
        })
    }

    const handleDeleteSelected = () => {
        deleteRecord({ url: `/quotes/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido eliminados exitosamente.',
                show: true
            })
            setSelectedValues([]);
            getQuotes();
        });
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <div>
            {
                permissions?.includes?.(mainPermissions?.quotes[1]) ?
                    <div className="my-4 justify-content-end d-flex">
                        <Link to={"/cotizaciones/crear"} className="btn btn-primary">
                            Crear Cotización
                        </Link>
                    </div>
                    :
                    null
            }

            <div className="row">
                <div className="col-md-4">
                    <div className="card p-4">
                        <label className="text-primary">
                            Número de cotización
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={filters?.id}
                            onChange={handleChange}
                            name="id"
                            placeholder="Número de cotización..."
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-4">
                        <label className="text-primary">
                            Elaborado Por:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={filters?.name}
                            onChange={handleChange}
                            name="name"
                            placeholder="Nombre del responsable..."
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-4">
                        <label className="text-primary">
                            Servicio
                        </label>
                        <select name="serviceIds" className="form-control" value={filters?.serviceIds} onChange={handleChange}>
                            <option value="">Seleccione uno</option>
                            {
                                services?.map((service, i) => {
                                    return (
                                        <option key={i} value={service?.id}>
                                            {service?.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Estatus
                        </label>
                        <select name="quoteStatusCode" className="form-control" value={filters?.quoteStatusCode} onChange={handleChange}>
                            <option value="">Seleccione uno</option>
                            {
                                orderStatuses?.map((orderStatus, i) => {
                                    return (
                                        <option key={i} value={orderStatus?.code}>{orderStatus?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Fecha
                        </label>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Desde:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="start"
                                    value={filters?.start}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Hasta:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="end"
                                    value={filters?.end}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Cotizaciones'}
                updatePath={"/mis-cotizaciones"}
                entity={"quotes"}
                updateOptionString={'Ver Detalles'}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={quotes}
                currentPage={filters?.page}
                collumns={QuotesColumns}
                changePage={handlePageChange}
            />
        </div>
    )
}

export default MyQuotes;