import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";

const CommentBox = ({ commentableId, commentableType }) => {

    const [filters, setFIlters] = useState({
        commentableId,
        commentableType
    });

    const [data, setData] = useState({
        commentable_id: commentableId,
        commentable_type: commentableType,
        message: ''
    });

    const [currentComments, setCurrentComments] = useState([]);

    const [{ data: comments, loading: loadingComments }, getComments] = useAxios({ url: `/comments`, params: filters }, { useCache: false });

    const [{ loading: loadingStoreComments }, storeComments] = useAxios({ url: `/comments`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (comments) {
            console.log(comments);
            setCurrentComments((oldComments) => {
                return [...oldComments, ...comments?.data];
            })
        }
    }, [comments]);

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        });
    }

    const onSubmit = (e) => {
        e?.preventDefault();

        storeComments({ data }).then((response) => {
            setCurrentComments((oldComments) => {
                return [...oldComments, response?.data?.data]
            });
            setData((oldData) => {
                return {
                    ...oldData,
                    message: ''
                }
            })
        })
    }

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">
                    Comentarios
                </h5>
            </div>
            <div className="card-body">
                {
                    !currentComments?.length &&
                    <div className="text-center">
                        No se encontrarón comentarios.
                    </div>

                }
                {
                    currentComments?.map((comment, i) => {
                        return (
                            <div key={i}>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <b>{comment?.user?.name}</b>
                                    </div>
                                </div>
                                <p>
                                    {comment?.message} | <b>{comment?.updated_at}</b>
                                </p>
                            </div>
                        )
                    })
                }
            </div>
            <div className="card-footer">
                <form onSubmit={onSubmit}>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Escribe tu comentario..."
                            onChange={handleChange}
                            value={data?.message}
                            name="message"
                        />
                        <button disabled={loadingStoreComments} class="btn btn-outline-primary">
                            {
                                loadingStoreComments ?
                                    'enviando'
                                    :
                                    'Enviar'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CommentBox;