import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import useUsers from "../../../hooks/useUsers";
import { handleLoadSelectOptions, mapValues } from "../../../util/Utilities";
import useServices from "../../../hooks/useServices";
import update from 'immutability-helper';
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";

const EppOrdersCreate = () => {

    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ params: { perPage: 200 } }, { useCache: false });

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: { perPage: 200 } }, { useCache: false });

    const [{ data: storeData, loading: loadingStore }, storeRecord] = useAxios({ url: `/epp-orders`, method: 'POST' }, { manual: true, useCache: false });

    const [data, setData] = useState({
        user: null,
        service: null,
        order_items: [
            {
                description: 'Camisa',
                value: ''
            },
            {
                description: 'Chaleco Geologo',
                value: ''
            },
            {
                description: 'Pantalon',
                value: ''
            },
            {
                description: 'Zapatos de seguridad',
                value: ''
            },
            {
                description: 'Chaqueta',
                value: ''
            },
            {
                description: 'Usa lentes opticos permanentes para trabajar',
                value: 'si'
            },
        ]
    });

    useEffect(() => {
        if (storeData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: `El registro fue creado exitosamente.`,
                show: true
            });
            navigate?.(`/solicitudes-de-hn`);
        }
    }, [storeData])

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleArrayChange = (e, index, arrayName) => {
        const newArrayValues = update(data?.[arrayName], { [index]: { [e.target.name]: { $set: e.target.value } } });
        setData((oldData) => {
            return {
                ...oldData,
                [arrayName]: newArrayValues
            }
        });
    }

    const handleSubmit = () => {
        storeRecord({
            data: {
                user_id: data?.user?.value,
                service_id: data?.service?.value,
                order_items: data?.order_items
            }
        })
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex align-items-center w-100">
                    <div>
                        <h5 className="card-title">
                            Crear Solicitud de epp
                        </h5>
                    </div>
                    <div style={{ marginLeft: 'auto' }} className="text-end">
                        <button className="btn btn-xs btn-primary" onClick={() => navigate(-1)}>
                            Volver
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label>Seleccione el trabajador</label>
                            <AsyncSelect
                                isClearable
                                onFocus={() => { getUsers() }}
                                value={data?.user}
                                isLoading={usersLoading}
                                defaultOptions={mapValues(users)}
                                name="user"
                                loadOptions={(e) => handleLoadSelectOptions(e, getUsers)}
                                placeholder='Escriba el nombre para buscar...'
                                onChange={(e) => { handleChange({ target: { value: e, name: 'user' } }) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label>Seleccione el servicio</label>
                            <AsyncSelect
                                isClearable
                                onFocus={() => { getServices() }}
                                value={data?.service}
                                isLoading={servicesLoading}
                                defaultOptions={mapValues(services)}
                                name="service"
                                loadOptions={(e) => handleLoadSelectOptions(e, getServices)}
                                placeholder='Escriba el nombre para buscar...'
                                onChange={(e) => { handleChange({ target: { value: e, name: 'service' } }) }}
                            />
                        </div>
                    </div>
                    {
                        data?.order_items?.map((orderItem, i) => {
                            return (
                                <div className="col-md-12">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text text-primary justify-content-start" id="basic-addon2" style={{ width: 310 }}>
                                            {orderItem?.description}
                                        </span>
                                        {
                                            i !== 5 ?
                                                <input type="text"
                                                    className="form-control"
                                                    value={orderItem?.value}
                                                    placeholder="Ingrese el valor..."
                                                    name="value"
                                                    onChange={(e) => handleArrayChange(e, i, 'order_items')}
                                                />
                                                :
                                                <select
                                                    className="form-control"
                                                    name="value"
                                                    onChange={(e) => handleArrayChange(e, i, 'order_items')}
                                                >
                                                    <option value="si">Si</option>
                                                    <option value="no">No</option>
                                                </select>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="card-footer text-end justify-content-end">
                <button className="btn btn-danger" onClick={() => navigate(-1)} type="button">
                    Cancelar
                </button>
                <button className="btn btn-primary ms-2" onClick={handleSubmit} disabled={loadingStore}>
                    Enviar
                </button>
            </div>
        </div>
    )
}
export default EppOrdersCreate;