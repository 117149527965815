export const cutString = (string, length, start, decorator) => {
    return string?.length > length ? `${string?.slice(start || 0, length)}${decorator}` : string;
}


export const dateFine = (date) => {
    const newDateSplited = date?.split('T');

    const day = newDateSplited?.[0];

    const hour = newDateSplited?.[1].split('.')?.[0];

    return `${day} ${hour}`;
}


export const handleLoadSelectOptions = async (e, functionGetter, additionalParams) => {
    const getData = await functionGetter({ params: { name: e, perPage: 50, page: 1, ...additionalParams } }, { useCache: false });
    return mapValues(getData?.data?.data);
}

export const mapValues = (values) => {
    const valuesMapped = values.map((value) => {
        return {
            label: value?.name,
            value: value?.id
        }
    });
    return valuesMapped;
}