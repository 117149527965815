import { useEffect } from "react";
import { useTheme } from "../../../../context/ThemeContext";
import useAxios from "../../../../hooks/useAxios";
import { cutString } from "../../../../util/Utilities";
import checkImage from '../../../../images/check.png';
import closeImage from '../../../../images/cancel.png';

const CreateOrderItemRow = ({ item, onRemove, index, onChange }) => {

    const { background } = useTheme();

    const [{ data: productExistsResponse, loading: productExistsLoading }, productExists] = useAxios({ url: `products/${item?.id}/exists` }, { manual: true, useCache: false });

    useEffect(() => {
        if (item?.id) {
            productExists();
        }
    }, [item])

    console.log(item);

    return (
        <tr style={{ border: productExistsResponse === 'no_existe' ? '1px red solid' : 'none' }}>
            <td>
                <div className="d-flex align-items-center">
                    {
                        productExistsLoading ?
                            <span>
                                <div className="spinner" style={{ maxHeight: '15px', maxWidth: '15px' }}>
                                    <div className="double-bounce1 bg-primary"></div>
                                    <div className="double-bounce2 bg-primary"></div>
                                </div>
                            </span>
                            :
                            item?.id ?
                                productExistsResponse === 'existe' ?
                                    <img src={checkImage} style={{ width: '13px', height: '13px', marginRight: 5 }} alt="" />
                                    :
                                    <img src={closeImage} style={{ width: '13px', height: '13px', marginRight: 5 }} alt="" />
                                :
                                null
                    }
                    <i
                        className="flaticon-381-trash-2 text-danger"
                        style={{ fontSize: '13px', cursor: 'pointer' }}
                        onClick={() => { onRemove(index) }}></i>
                </div>
            </td>
            <td>{item?.code}</td>
            <td width={"30%"} colSpan={2} title={item?.name}>{cutString(`${item?.name}`, 20, 0, '...')}</td>
            <td>
                <input type="number" min={1} style={{
                    borderRadius: '8px',
                    width: '100%',
                    border: '1px solid whitesmoke',
                    padding: '5px',
                    background: 'transparent',
                    color: background?.value === 'light' ? 'black' : 'white'
                }} name="quantity" value={item?.quantity} onChange={(e) => { onChange(e, index) }} />
            </td>
            <td>${Number(item?.price).toFixed(0)}</td>
            <td>$ {item?.price * item?.quantity}</td>
        </tr>
    )
}

export default CreateOrderItemRow;