import Toggle from "react-toggle";
import ImgUploadInput from "../../ImgUploadInput";
import { useRef, useState } from "react";
import clsx from "clsx";

const ManualOrderForm = ({ setData, data }) => {

    const inputFileRef = useRef();

    const [newItemData, setNewItemData] = useState({
        name: '',
        price: 0,
        providerName: '',
        description: '',
        image: null,
        file: null,
        type: 'product'
    });

    const handleNewItemChange = (e) => {
        setNewItemData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e?.target?.files?.[0] : e.target.value
            }
        });
    }

    const handleChangeForm = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleAddNewItem = (e) => {
        e.preventDefault?.();
        if (
            !newItemData?.name ||
            !newItemData?.price ||
            !newItemData?.providerName ||
            !newItemData?.description ||
            !newItemData?.type
        ) {
            alert('Todos lo campos son obligatorios');
            return;
        }

        if (!newItemData?.image && !newItemData?.file) {
            alert('Tienes que llenar el campo imagen o el campo archivo.');
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                orderItems: [
                    ...oldData?.orderItems,
                    {
                        name: newItemData?.name,
                        image: newItemData?.image || null,
                        file: newItemData?.file || null,
                        providerName: newItemData?.providerName,
                        description: newItemData?.description,
                        quantity: 1,
                        price: newItemData?.price,
                        type: newItemData?.type,
                        code: '--',
                    }
                ]
            }
        });
        setNewItemData((oldValue) => {
            return {
                name: '',
                price: 0,
                providerName: '',
                description: '',
                image: null,
                file: null,
                type: oldValue?.type
            }
        });

        inputFileRef.current.value = '';
    }

    return (
        <form onSubmit={handleAddNewItem} className="col-md-7 card p-5">
            <div className="row">
                <div className="col-md-12 align-items-center justify-content-between d-flex">
                    <label className="d-block">
                        <h4>Cobro por formular:</h4>
                    </label>
                    <Toggle onChange={() => setData((oldData) => {
                        return {
                            ...oldData,
                            chargePerForm: !oldData?.chargePerForm
                        }
                    })} checked={data?.chargePerForm} />
                </div>
            </div>
            {
                data?.chargePerForm &&
                <div className="row animate__animated animate__fadeInRight mb-4">
                    <div className="col-md-4 form-group">
                        <label>Autorizado por</label>
                        <input
                            type="text"
                            value={data?.authorizedBy}
                            name="authorizedBy"
                            className="form-control"
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>Cuenta</label>
                        <input
                            type="text"
                            value={data?.account}
                            name="account"
                            className="form-control"
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>Cebe</label>
                        <input
                            type="text"
                            value={data?.seven}
                            name="seven"
                            className="form-control"
                            onChange={handleChangeForm}
                        />
                    </div>
                </div>
            }
            <div className="border-bottom"></div>
            <br />
            <div className="row align-items-center">
                <div className="col-md-12 mb-5">
                    <h3 className="text-center">
                        Información del producto
                    </h3>
                </div>
                <div className="col-md-6 form-group mb-4">
                    <ImgUploadInput
                        name="image"
                        style={{
                            width: '100px',
                            height: '100px'
                        }}
                        value={newItemData?.image}
                        description="imagen"
                        deleteButton={newItemData?.image}
                        change={handleNewItemChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="text-primary">Tipo de producto</label>
                    <select name="type" id="" onChange={handleNewItemChange} value={newItemData?.type} className="form-control">
                        <option value="product">Material</option>
                        <option value="service">Servicio</option>
                    </select>
                </div>
                <div className="col-md-6 form-group mb-4">
                    <label>Nombre</label>
                    <input placeholder="nombre" autoFocus value={newItemData?.name} onChange={handleNewItemChange} name="name" type="text" className="form-control" />
                </div>
                <div className="col-md-6 form-group mb-4">
                    <label>Precio Referencia</label>
                    <input placeholder="precio" value={newItemData?.price} onChange={handleNewItemChange} name="price" type="number" className="form-control" />
                </div>
                <div className="col-md-6 form-group mb-4">
                    <label>Proveedor</label>
                    <input placeholder="proveedor" value={newItemData?.providerName} type="text" className="form-control" name="providerName" onChange={handleNewItemChange} />
                </div>
                <div className="col-md-6 form-group mb-4">
                    <label>Documento</label>
                    <div className="text-center">
                        <label
                            htmlFor={`file-input`}
                            className={clsx("btn", {
                                "btn-danger": !newItemData?.file,
                                "btn-success": newItemData?.file,
                            })}
                            title="Cargar Archivo"
                        >
                            <i className="flaticon-022-copy"></i>
                        </label>
                        {
                            newItemData?.file &&
                            <>
                                <p style={{ margin: 0 }} title={newItemData?.file?.name}>
                                    {newItemData?.file?.name?.length > 20 ? `${newItemData?.file?.name?.slice(0, 20)}...` : newItemData?.file?.name}
                                </p>
                                <button
                                    type="button" onClick={() => handleNewItemChange({ target: { files: [''], type: 'file', name: 'file' } })}
                                    className="text-danger btn"
                                    title="Remover Archivo"
                                >
                                    X
                                </button>
                            </>
                        }
                        <input
                        ref={inputFileRef}
                            type="file"
                            name="file"
                            accept="application/pdf,application/vnd.ms-excel,application/msword,image/*"
                            className="form-control d-none"
                            id={`file-input`}
                            onChange={(e) => handleNewItemChange(e)}
                        />
                    </div>
                </div>
                <div className="col-md-12 form-group mb-2">
                    <label>Descripción</label>
                    <textarea
                        rows={4}
                        value={newItemData?.description}
                        style={{ minHeight: '100px' }}
                        className="form-control"
                        name="description"
                        onChange={handleNewItemChange}
                        placeholder="descripción"
                    />
                </div>
                <div className="col-md-12 text-center">
                    <p className="text-danger text-center">
                        ⚠️ Los equipos y repuestos informáticos deben ser canalizados con Soporte TI ⚠️
                    </p>
                    <button onClick={handleAddNewItem} className="btn btn-success">
                        Agregar
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ManualOrderForm;