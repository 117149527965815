import clsx from "clsx";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ObservationsForm from "../../../components/Observations/ObservationsForm";
import OrdersSideCard from "../../../components/Order/OrdersSideCard";
import OrderItemRow from "../../../components/OrderItemRow";
import RenderStatus from "../../../components/RenderStatus";
import { useAuth } from "../../../context/AuthContext";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useOrderStatuses from "../../../hooks/useOrderStatuses";
import CommentBox from "../../../components/CommentBox";


const EppOrdersDetails = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { setLoading } = useFeedBack();

    const [currentOrderDetails, setCurrentOrderDetails] = useState(null);

    const [{ data: orderDetails, loading: loadingOrderDetails }] = useAxios({ url: `/epp-orders/${id}` }, { useCache: false });

    const [{ loading: loadingStatus }, updateStatus] = useAxios({ url: `/epp-orders/${id}/status`, method: 'POST' }, { useCache: false, manual: true });

    useEffect(() => {
        if (orderDetails) {
            setCurrentOrderDetails((oldOrderDetails) => {
                return {
                    ...oldOrderDetails,
                    ...orderDetails?.data
                }
            });
        }
    }, [orderDetails]);

    useEffect(() => {
        setLoading?.({
            show: loadingOrderDetails,
            message: 'Obteniendo Información'
        })
    }, [loadingOrderDetails]);

    const handleStatus = (status) => {
        updateStatus({ data: { status_id: status?.id } }).then(res => {
            setCurrentOrderDetails(res.data.data);
        });
    }

    return (
        <div>
            <div className="text-end my-4">
                <button className="mx-4 btn btn-primary" onClick={() => navigate?.(-1)}>
                    Volver Al listado
                </button>
                <Link to="/solicitudes-de-hn/crear" className="mx-4 btn btn-primary">
                    Crear Solicitud
                </Link>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5>
                                        Trabajador
                                    </h5>
                                    <input type="text" readOnly value={`${currentOrderDetails?.user?.name} - ${currentOrderDetails?.user?.documentNumber}`} className="form-control mb-3" />
                                </div>
                                <div className="col-md-6">
                                    <h5>
                                        Servicio
                                    </h5>
                                    <input type="text" readOnly value={currentOrderDetails?.service?.name} className="form-control" />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <h5 className="text-center">
                                        Items de la solicitud
                                    </h5>
                                </div>
                                {
                                    currentOrderDetails?.orderItems?.map((orderItem, i) => {
                                        return (
                                            <div className="col-md-12" key={i}>
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text text-primary justify-content-start" id="basic-addon2" style={{ width: 310 }}>
                                                        {orderItem?.description}
                                                    </span>

                                                    <input type="text"
                                                        className="form-control"
                                                        value={orderItem?.value}
                                                        placeholder="Ingrese el valor..."
                                                        name="value"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="card-footer">
                            <Dropdown>
                                <Dropdown.Toggle variant={currentOrderDetails?.status?.variant_color} id="dropdown-basic" className="w-100 text-capitalize">
                                    {
                                        loadingStatus ?
                                            <div className="spinner">
                                                <div className="double-bounce1 bg-light"></div>
                                                <div className="double-bounce2 bg-light"></div>
                                            </div>
                                            :
                                            currentOrderDetails?.status?.name
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        currentOrderDetails?.availableStatuses?.map((status, i) => {
                                            return (
                                                <Dropdown.Item onClick={() => handleStatus(status)} key={i} className={`text-capitalize text-${status?.variant_color}`}>
                                                    {status?.name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">
                                Historial de estados
                            </h5>
                        </div>
                        <div className="card-body custom-scrollbar scrollbar-primary" style={{ maxHeight: 420, overflowY: 'auto' }}>
                            <ul className="list-group">
                                {
                                    currentOrderDetails?.statuses?.map((orderStatus, i) => {
                                        return (
                                            <li className="list-group-item" key={i} style={{ borderBottomColor: orderStatus?.status?.color }}>
                                                <b>
                                                    {orderStatus?.createdAt}
                                                </b>
                                                <p>
                                                    El usuario <b> {orderStatus?.createdBy?.name}</b> ha marcado la orden como:
                                                    <b className={`text-capitalize text-${orderStatus?.status?.variant_color}`}> {orderStatus?.status?.name}</b>
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <CommentBox
                        commentableId={id}
                        commentableType="App\Models\EppOrder"
                    />
                </div>
            </div>
        </div >
    )
}

export default EppOrdersDetails;