import { formatDistance } from "date-fns";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { es } from "date-fns/locale";

const OrderStatusHistoryButton = ({ order }) => {

    const [showOrderStatusHistoryModal, setShowOrderStatusHistoryModal] = useState(false);

    const timeDistance = (date) => {
        if (date) {
            const dateDistance = formatDistance(new Date(date), new Date(), {
                locale: es
            });

            return dateDistance;
        }
    }

    return (
        <div>
            <button onClick={() => setShowOrderStatusHistoryModal(true)} className="btn btn-block btn-primary position-relative">
                <p style={{ margin: 0 }}>Mostrar Historial de Estatus</p>
            </button>
            <Modal className="fade" size="lg" show={showOrderStatusHistoryModal}>
                <Modal.Header>
                    <Modal.Title>Historial de estatus de la orden:</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowOrderStatusHistoryModal(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {
                            order?.orderStatusHistory?.map((status, i) => {
                                return (
                                    <li key={i} className="px-2 mb-2" style={{ borderLeft: `3px solid ${status?.orderStatus?.color}` }}>
                                        <p className="mb-0 text-dark">
                                            Hace: {timeDistance(status?.createdAt)}
                                        </p>
                                        <p style={{ color: status?.orderStatus?.color, margin: 0 }}>
                                            {status?.orderStatus?.name}
                                        </p>
                                        <p>
                                            <b>{status?.user?.name}</b> ha cambiado el estatus de la orden a: <b style={{ color: status?.orderStatus?.color }}>{status?.orderStatus?.name}</b>.
                                        </p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrderStatusHistoryButton;