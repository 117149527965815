import clsx from "clsx";
import { useTheme } from "../../../context/ThemeContext";
import { useEffect, useState } from "react";
import useCategories from "../../../hooks/useCategories";

const OrdersCreateFiltersForm = ({ setFilters, filters }) => {
    const { background } = useTheme();

    const [showFilters, setShowFilters] = useState(false);

    const [categoriesFilters, setCategoriesFilters] = useState({
        page: 1,
        perPage: 200,
        parentsOnly: true
    });

    const [subCategoriesFilters, setSubCategoriesFilters] = useState({
        page: 1,
        perPage: 200
    });

    const [{ categories, loading: loadingCategories }] = useCategories({ params: { ...categoriesFilters } });

    const [{ categories: subCategories, loading: loadingSubCategories }, getSubCategories] = useCategories({ params: { ...categoriesFilters }, options: { manual: true } });

    useEffect(() => {
        if (filters?.categoryId) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    subCategoryId: ''
                }
            });
            getSubCategories({
                params: {
                    ...subCategoriesFilters,
                    parentId: filters?.categoryId
                }
            });
        }
    }, [filters.categoryId])


    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    const handleResetFilters = () => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                name: '',
                reference: '',
                providerName: '',
                price: '',
                page: 1,
                subCategoryId: '',
                categoryId: ''
            }
        })
    }

    return (
        <div style={{ height: 'fit-content' }} className={clsx(["mb-4 rounded shadow-sm"], {
            "bg-white": background?.value === 'light',
            'card': background?.value !== 'light'
        })}>
            <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                    <h5>
                        Filtros
                    </h5>
                    <button type="button" className="btn btn-primary" onClick={() => setShowFilters(oldValue => !oldValue)}>
                        {
                            !showFilters ?
                                <i className="ti ti-arrow-down"></i>
                                :
                                <i className="ti ti-arrow-up"></i>
                        }
                    </button>
                </div>

            </div>
            {
                showFilters &&
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 form-group mb-2">
                            <input
                                className="form-control"
                                placeholder="Nombre..."
                                name="name"
                                onChange={handleChange}
                                value={filters?.name}
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <input
                                className="form-control"
                                placeholder="proveedor..."
                                name="providerName"
                                onChange={handleChange}
                                value={filters?.providerName}
                            />
                        </div>
                        <div className="col-md-12 form-group mb-2">
                            <input
                                className="form-control"
                                placeholder="Código..."
                                name="code"
                                onChange={handleChange}
                                value={filters?.code}
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <select disabled={loadingCategories} className="form-control" onChange={handleChange} value={filters?.categoryId} name="categoryId">
                                <option value="">
                                    Categoría
                                </option>
                                {
                                    categories?.map((category, i) => {
                                        return (
                                            <option value={category?.id} key={i}>
                                                {category?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <select disabled={!filters?.categoryId || loadingSubCategories} className="form-control" name="subCategoryId" value={filters?.subCategoryId} onChange={handleChange}>
                                <option value="">
                                    Sub - Categorías
                                </option>
                                {
                                    subCategories?.map((category, i) => {
                                        return (
                                            <option value={category?.id} key={i}>
                                                {category?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                            <button className="btn btn-danger btn-sm" onClick={handleResetFilters}>
                                Limpiar filtros
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default OrdersCreateFiltersForm;