import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { useOrderCrud } from "../../../../context/OrderCrudContext";
import Stepper from "../../../Stepper/Stepper";
import { useEffect, useState } from "react";
import useAxios from "../../../../hooks/useAxios";




const CreateOrderForm = () => {

    const { currentStep, canNext, setCurrentStep, data, setData } = useOrderCrud();

    const [{ data: autoSaveData, loading: loadingAutoSave }, getAutoSave] = useAxios({ url: `/auto-saves/user` }, { useCache: false });

    const [{ data: storeAutoSaveResponse, loading: loadingStoreAutoSave }, storeAutoSave] = useAxios({ url: `/auto-saves`, method: 'POST' }, { useCache: false, manual: true });

    const [canSave, setCanSave] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    const [showSaveSuccess, setShowSaveSuccess] = useState(false);

    const [dots, setDots] = useState("");

    useEffect(() => {
        if (storeAutoSaveResponse) {
            setShowSaveSuccess(true);
        }
    }, [storeAutoSaveResponse]);

    useEffect(() => {
        if (showSaveSuccess) {
            setTimeout(() => {
                setShowSaveSuccess(false);
            }, 3000)
        }
    }, [showSaveSuccess])

    useEffect(() => {
        console.log(data);
    }, [data])

    useEffect(() => {
        if (canSave && data?.orderTypeId != 3) {
            storeAutoSave({
                data: {
                    data: JSON.stringify({
                        data: data,
                        step: currentStep
                    }),
                    for: 'order'
                }
            });
        }
    }, [data, currentStep]);

    useEffect(() => {
        if (autoSaveData) {
            setShowAlert(true);
            setCanSave(true);
        }
    }, [autoSaveData])

    useEffect(() => {
        let id;

        if (loadingAutoSave || loadingStoreAutoSave) {
            id = setInterval(() => {
                setDots((oldDots) => oldDots.length < 3 ? oldDots + "." : "");
            }, 500);
        }

        return () => {
            if (id) clearInterval(id);
        }
    }, [loadingAutoSave, loadingStoreAutoSave]);

    const steps = [
        { name: "Paso 1", Component: StepOne },
        { name: "Paso 2", Component: StepTwo },
        { name: "Paso 3", Component: StepThree },
        { name: "Paso 4", Component: StepFour },
    ];

    const handleLoadAutoSave = (e) => {
        setData(autoSaveData?.data?.data);
        setCurrentStep(autoSaveData?.data?.step);
        setShowAlert(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <Stepper
                        steps={steps}
                        currentStep={currentStep}
                    />
                </div>
            </div>
            {
                loadingAutoSave &&
                <div className="animate__animated animate__fadeIn btn btn-light btn-xs" style={{ position: 'fixed', bottom: 50, left: '45vw', zIndex: 100 }}>
                    Buscando auto guardado{dots}
                </div >
            }

            {
                loadingStoreAutoSave &&
                <div className="animate__animated animate__fadeIn btn btn-light btn-xs" style={{ position: 'fixed', bottom: 50, left: '45vw', zIndex: 100 }}>
                    Guardando{dots}
                </div >
            }

            {
                autoSaveData && autoSaveData !== 'no_existe' && showAlert && data?.orderTypeId !== 3 ?
                    <div className="animate__animated animate__fadeIn" style={{ background: 'whitesmoke', position: 'fixed', bottom: 50, left: '44vw', zIndex: 100, padding: 10, color: 'black', borderRadius: '10px', boxShadow: '0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)' }}>
                        <div>
                            <p className="mb-1">
                                Se ha encontrado un autoguardado.
                            </p>
                            <div>
                                <button onClick={() => setShowAlert(false)} className="btn btn-outline-danger btn-xs mx-1">
                                    Omitir
                                </button>
                                <button onClick={handleLoadAutoSave} className="animate__animated animate__pulse animate__infinite btn btn-outline-primary btn-xs mx-1">
                                    Cargar autoguardado
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {
                showSaveSuccess && !loadingStoreAutoSave ?
                    <div className="animate__animated animate__fadeIn btn btn-xs btn-success" style={{ position: 'fixed', bottom: 50, left: '44vw', zIndex: 100 }}>
                        Se ha guardado exitosamente.
                    </div>
                    :
                    null
            }

        </>
    )
}
export default CreateOrderForm;
