import useAxios from "../../hooks/useAxios";
import DateFormatter from "../DateFormatter";
import swal from "sweetalert";

const OrderFileRow = ({ file, onDelete, number }) => {

    const [{ }, getOrderFile] = useAxios({ method: 'GET', responseType: 'blob' }, { useCache: false });

    const [{ loading: loadingDeleteOrderFile }, deleteOrderFile] = useAxios({ method: 'DELETE', url: `/order-files/${file?.id}` }, { manual: true, useCache: false });

    const handleFindFile = (filePath, fileName) => {
        getOrderFile({
            url: `/files${filePath}`
        }).then((response) => {
            handleBlobResponse(response?.data, fileName);
        });
    }

    const handleBlobResponse = (blobResponse, fileName) => {
        const fileBlobUrl = URL.createObjectURL(blobResponse);
        const aToDownload = document.getElementById('downloadLink');
        aToDownload.download = fileName;
        aToDownload.href = fileBlobUrl;
        aToDownload?.click();
        window.URL.revokeObjectURL(fileBlobUrl);
    }

    const handleDeleteFile = async () => {
        swal({
            title: "¿Estas seguro?",
            text: "¿quieres eliminar todos los registros?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    const response = await deleteOrderFile();
                    onDelete?.(number - 1);
                } catch (error) {

                }
            } else {

            }
        })
    }

    return (
        <tr>
            <td>
                {number}
            </td>
            <td>
                {file?.originalName}
            </td>
            <td>
                <DateFormatter value={file?.createdAt} dateFormat="dd-MM-yyyy hh:mm:ss" />
            </td>
            <td>
                <button onClick={() => handleFindFile(file?.filePath, `guia-de-despacho-${number}`)} className="btn btn-xs mx-2 btn-primary">
                    Descargar
                </button>
                <button disabled={loadingDeleteOrderFile} onClick={handleDeleteFile} className="btn btn-xs mx-2 btn-danger">
                    {
                        loadingDeleteOrderFile ?
                            'Cargando'
                            :
                            'Eliminar'
                    }
                </button>
            </td>
        </tr>
    )
}

export default OrderFileRow;