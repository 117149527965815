import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useAuth } from "../../../context/AuthContext";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useServices from "../../../hooks/useServices";
import { mainPermissions } from "../../../util/MenuLinks";
import useEppOrders from "../../../hooks/useEppOrders";
import useStatuses from "../../../hooks/useStatuses";
import LensOrdersColumns from "../../../components/CustomTable/Columns/LensOrdersColumns";
import useLensOrders from "../../../hooks/useLensOrders";

const LensOrders = () => {

    const { permissions, isSuperAdmin } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        serviceId: '',
        start: '',
        end: '',
        statusId: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: { perPage: 500 } }, { useCache: false });

    const [{ statuses }, getStatuses] = useStatuses({ options: { useCache: false } });

    const [{ lensOrders, total, numberOfPages, error: ordersError, loading }, getOrders] = useLensOrders({ params: { ...filters }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteProvider] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        //getOrders();
    }, [])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registros'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }

        if (ordersError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los registros.',
                show: true
            });
        }
    }, [deleteError, ordersError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(lensOrders?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteProvider({ url: `/lens-orders/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'El registro ha sido eliminado exitosamente.',
                show: true
            });
            getOrders();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {

        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                page: page
            }
        })

    }

    const handleDeleteSelected = () => {
        deleteProvider({ url: `/lens-orders/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido eliminados exitosamente.',
                show: true
            })
            setSelectedValues([]);
            getOrders();
        });
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            {
                permissions?.includes?.(mainPermissions?.lensOrders[1]) || isSuperAdmin ?
                    <div className="my-4 justify-content-end d-flex">
                        <Link to={"/solicitudes-de-lentes/crear"} className="btn btn-primary">
                            Crear Solicitud De Lentes
                        </Link>
                    </div>
                    :
                    null
            }

            <div className="row">
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Número de solicitud
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={filters?.id}
                            onChange={handleChange}
                            name="id"
                            placeholder="Número de solicitud..."
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Servicio
                        </label>
                        <select name="serviceId" className="form-control" value={filters?.serviceId} onChange={handleChange}>
                            <option value="">Seleccione uno</option>
                            {
                                services?.map((service, i) => {
                                    return (
                                        <option key={i} value={service?.id}>
                                            {service?.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Estatus
                        </label>
                        <select name="statusId" className="form-control" value={filters?.statusId} onChange={handleChange}>
                            <option value="">Seleccione uno</option>
                            {
                                statuses?.map((status, i) => {
                                    return (
                                        <option
                                            style={{ textTransform: 'capitalize' }}
                                            key={i}
                                            value={status?.id}
                                        >
                                            {status?.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Fecha
                        </label>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Desde:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="start"
                                    value={filters?.start}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Hasta:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="end"
                                    value={filters?.end}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Solicitudes de lentes'}
                updatePath={"/solicitudes-de-lentes"}
                entity={"lens-orders"}
                updateOptionString={'Ver Detalles'}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={lensOrders}
                currentPage={filters?.page}
                collumns={LensOrdersColumns}
                changePage={handlePageChange}
                filters={filters}
                excelUrl={'/lens-orders/export/excel'}
            />
        </div >
    )
}
export default LensOrders;