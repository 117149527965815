import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import useUsers from "../../../hooks/useUsers";
import { handleLoadSelectOptions, mapValues } from "../../../util/Utilities";
import useServices from "../../../hooks/useServices";
import update from 'immutability-helper';
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";

const LensOrdersCreate = () => {

    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ params: { perPage: 200 } }, { useCache: false });

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: { perPage: 200 } }, { useCache: false });

    const [{ data: storeData, loading: loadingStore }, storeRecord] = useAxios({ url: `/lens-orders`, method: 'POST' }, { manual: true, useCache: false });

    const [data, setData] = useState({
        user: null,
        service: null,
        file: null
    });

    useEffect(() => {
        if (storeData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: `El registro fue creado exitosamente.`,
                show: true
            });
            navigate?.(`/solicitudes-de-lentes`);
        }
    }, [storeData])

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        });
    }

    const handleSubmit = () => {
        const dataToSend = new FormData();

        dataToSend.append('user_id', data?.user?.value);
        dataToSend.append('service_id', data?.service?.value);
        dataToSend.append('file', data?.file, data?.file?.name);

        storeRecord({ data: dataToSend })
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex align-items-center w-100">
                    <div>
                        <h5 className="card-title">
                            Crear Solicitud de lentes
                        </h5>
                    </div>
                    <div style={{ marginLeft: 'auto' }} className="text-end">
                        <button className="btn btn-xs btn-primary" onClick={() => navigate(-1)}>
                            Volver
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label>Seleccione el trabajador</label>
                            <AsyncSelect
                                isClearable
                                onFocus={() => { getUsers() }}
                                value={data?.user}
                                isLoading={usersLoading}
                                defaultOptions={mapValues(users)}
                                name="user"
                                loadOptions={(e) => handleLoadSelectOptions(e, getUsers)}
                                placeholder='Escriba el nombre para buscar...'
                                onChange={(e) => { handleChange({ target: { value: e, name: 'user' } }) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label>Seleccione el servicio</label>
                            <AsyncSelect
                                isClearable
                                onFocus={() => { getServices() }}
                                value={data?.service}
                                isLoading={servicesLoading}
                                defaultOptions={mapValues(services)}
                                name="service"
                                loadOptions={(e) => handleLoadSelectOptions(e, getServices)}
                                placeholder='Escriba el nombre para buscar...'
                                onChange={(e) => { handleChange({ target: { value: e, name: 'service' } }) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-3">
                            <label>Formula</label>
                            <input
                                className="form-control"
                                type="file"
                                onChange={handleChange}
                                name="file"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer text-end justify-content-end">
                <button className="btn btn-danger" onClick={() => navigate(-1)} type="button">
                    Cancelar
                </button>
                <button className="btn btn-primary ms-2" onClick={handleSubmit} disabled={loadingStore}>
                    Enviar
                </button>
            </div>
        </div>
    )
}
export default LensOrdersCreate;