import { useEffect, useState } from "react";
import { useOrderCrud } from "../../../../context/OrderCrudContext";
import useServices from "../../../../hooks/useServices";
import check from "../../../../images/check.png";
import cancel from "../../../../images/cancel.png";

const StepOne = () => {

    const { data, setData, currentStep, setCurrentStep } = useOrderCrud();

    const [filters, setFilters] = useState({
        perPage: 200,
        currentUserServices: true,
        page: 1,
        name: ''
    });

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: filters, options: { useCache: false } });

    useEffect(() => {
        if (services?.length === 1) {
            setData((oldData) => {
                return {
                    ...oldData,
                    serviceId: services[0]?.id
                }
            });
        }
    }, [services])


    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <div className="container">
            <div className="card col-md-12 animate__animated animate__fadeInUp">
                <h4 style={{ textAlign: 'center', padding: "20px", borderBottom: '1px solid whitesmoke' }}>Seleccione el servicio</h4>
                {
                    data?.serviceId ?
                        <div className="text-center mt-5 animate__animated animate__fadeInUp">
                            <img src={check} style={{ width: '100px' }} />
                            <h3>Perfecto ya puedes continuar</h3>
                        </div>
                        :
                        <div className="text-center mt-5 animate__animated animate__fadeInUp">
                            <img src={cancel} style={{ width: '100px' }} />
                            <h3>Por favor seleccione un servicio</h3>
                        </div>
                }

                <div className="card-body">


                    <input type="text" className="form-control mb-5" placeholder="Escriba el nombre..." value={filters?.name} onChange={(e) => {
                        setFilters((oldFilters) => {
                            return {
                                ...oldFilters,
                                name: e?.target?.value,
                                page: 1
                            }
                        })
                    }} />

                    {
                        servicesLoading &&
                        <div className="text-center">
                            <p>Por favor espere...</p>
                        </div>
                    }
                    {
                        services?.length === 0 && !servicesLoading ?
                            <h5 className="text-muted">
                                No se encontrarón resultados.
                            </h5>
                            :
                            null

                    }
                    <ul className="custom-scrollbar scrollbar-primary p-1" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                        {
                            services?.map((service, i) => {
                                return (
                                    <li key={i}>
                                        <label htmlFor={`service-${service?.id}`} className="d-flex align-items-center">
                                            <input
                                                id={`service-${service?.id}`}
                                                onChange={handleChange}
                                                checked={data?.serviceId == service?.id}
                                                type="radio"
                                                name="serviceId"
                                                style={{ marginRight: 10 }}
                                                value={service?.id}
                                            />
                                            <p className="m-0">{service?.name}</p>
                                        </label>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {/* <select className="form-control" name="serviceId" onChange={handleChange} value={data?.serviceId}>
                        <option>Seleccione un servicio</option>
                        {
                            services?.map((service, i) => {
                                return (
                                    <option key={i} value={service?.id}>{service?.name}</option>
                                )
                            })
                        }
                    </select> */}
                </div>
                <div className="card-footer text-end">
                    <button type="button" disabled={!data?.serviceId} className="btn btn-primary" onClick={() => { setCurrentStep((oldStep) => oldStep + 1) }}>
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    )
}

export default StepOne;