import { FaCloudDownloadAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import useAxios from "../hooks/useAxios";
import swal from "sweetalert";

const OrderFileComponent = ({ currentOrderDetails, orderFile, onDelete }) => {

    const [{ loading }, deleteOrderFile] = useAxios({ url: `/lens-orders/${currentOrderDetails?.id}/files/${orderFile?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDelete = () => {
        swal({
            title: "¿Estas seguro?",
            text: "¿quieres eliminar el registro?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) deleteOrderFile?.().then(() => onDelete?.());
        });
    }

    return (
        <div class="input-group mb-3">
            <input
                type="text"
                readOnly
                class="form-control"
                value={orderFile?.name}
            />
            <a
                href={orderFile?.path}
                download={orderFile?.name}
                class="btn btn-outline-secondary"
                title="Descargar"
                target="_blank"
            >
                <FaCloudDownloadAlt />
            </a>
            {
                currentOrderDetails?.availableStatuses?.length > 0 &&
                <button disabled={loading} class="btn btn-outline-danger" type="button" title="Eliminar" onClick={handleDelete}>
                    {
                        loading ?
                            <div className="spinner">
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            <MdDeleteForever />
                    }
                </button>
            }

        </div>
    )
}

export default OrderFileComponent;