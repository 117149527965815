import { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import { FaCloudUploadAlt } from "react-icons/fa";
import OrderFileComponent from "../../../components/OrderFileComponent";
import CommentBox from "../../../components/CommentBox";



const LensOrdersDetails = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { setLoading } = useFeedBack();

    const [count, setCount] = useState(0);

    const [toCount, setToCount] = useState(0);

    const [filesWithErrors, setFileWithErrors] = useState([]);



    const [currentOrderDetails, setCurrentOrderDetails] = useState(null);

    const [{ data: orderDetails, loading: loadingOrderDetails }, getOrder] = useAxios({ url: `/lens-orders/${id}` }, { useCache: false });

    const [{ loading: loadingStatus }, updateStatus] = useAxios({ url: `/lens-orders/${id}/status`, method: 'POST' }, { useCache: false, manual: true });

    const [{ data: storeData, loading: storeLoading }, storeFile] = useAxios({ url: `/lens-orders/${id}/files`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (orderDetails) {
            setCurrentOrderDetails((oldOrderDetails) => {
                return {
                    ...oldOrderDetails,
                    ...orderDetails?.data
                }
            });
        }
    }, [orderDetails]);

    useEffect(() => {
        setLoading?.({
            show: loadingOrderDetails,
            message: 'Obteniendo Información'
        })
    }, [loadingOrderDetails]);

    const handleStatus = (status) => {
        updateStatus({ data: { status_id: status?.id } }).then(res => {
            setCurrentOrderDetails(res.data.data);
        });
    }

    const handleFiles = async (e) => {
        setToCount(e.target.files.length);
        setFileWithErrors([]);
        var uploadedFiles = 0;
        for (let index = 0; index < e.target.files.length; index++) {
            const newFile = e.target.files[index];
            try {
                var data = new FormData();

                data?.append('file', newFile, newFile?.name);

                const fileResponse = await storeFile({ data });

                if (fileResponse) {
                    uploadedFiles = uploadedFiles + 1;
                    setCount(oldCount => oldCount + 1);
                    setCurrentOrderDetails(fileResponse?.data?.data);
                }
            } catch (error) {
                setFileWithErrors(oldValue => {
                    return [...oldValue, { file_name: newFile?.name, error_message: error?.response?.data?.message }]
                });
            }
        }
    }

    return (
        <div>
            <div className="text-end my-4">
                <button className="mx-4 btn btn-primary" onClick={() => navigate?.(-1)}>
                    Volver Al listado
                </button>
                <Link to="/solicitudes-de-epp/crear" className="mx-4 btn btn-primary">
                    Crear Solicitud
                </Link>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5>
                                        Trabajador
                                    </h5>
                                    <input type="text" readOnly value={`${currentOrderDetails?.user?.name} - ${currentOrderDetails?.user?.documentNumber}`} className="form-control mb-3" />
                                </div>
                                <div className="col-md-6">
                                    <h5>
                                        Servicio
                                    </h5>
                                    <input type="text" readOnly value={currentOrderDetails?.service?.name} className="form-control" />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <h3 className="text-center">
                                        Archivos
                                    </h3>
                                </div>
                                {

                                    currentOrderDetails?.files?.map((orderFile, i) => {
                                        return (
                                            <OrderFileComponent
                                                orderFile={orderFile}
                                                onDelete={() => getOrder()}
                                                currentOrderDetails={currentOrderDetails}
                                            />

                                        )
                                    })


                                }
                                {
                                    currentOrderDetails?.availableStatuses?.length > 0 ?
                                        <>
                                            {
                                                storeLoading ?
                                                    <div className="col-12 text-center my-5">
                                                        <Spinner animation="grow" variant="primary" />
                                                        <p>
                                                            Cargando {count} de {toCount} archivos.
                                                        </p>
                                                    </div>
                                                    :
                                                    <div className="col-12">
                                                        <label htmlFor="file-input" className="d-flex border w-100" style={{ height: 200, cursor: 'pointer' }}>
                                                            <p className="m-auto text-center">
                                                                <FaCloudUploadAlt className="d-block m-auto" style={{ fontSize: 80 }} />
                                                                Haz click aqui para subir los archivos.
                                                            </p>
                                                            <input type="file" id="file-input" multiple className="d-none" onChange={handleFiles} />
                                                        </label>
                                                    </div>
                                            }
                                            {
                                                !storeLoading ?
                                                    filesWithErrors?.map((fileError) => {
                                                        return (
                                                            <div className="alert alert-danger">
                                                                <b>Error:</b> El archivo <b>{fileError?.file_name}</b> no se ha podido subir.
                                                                <br />
                                                                <b>Motivo:</b> {fileError?.error_message}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="card-footer">
                            <Dropdown>
                                <Dropdown.Toggle variant={currentOrderDetails?.status?.variant_color} id="dropdown-basic" className="w-100 text-capitalize">
                                    {
                                        loadingStatus ?
                                            <div className="spinner">
                                                <div className="double-bounce1 bg-light"></div>
                                                <div className="double-bounce2 bg-light"></div>
                                            </div>
                                            :
                                            currentOrderDetails?.status?.name
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        currentOrderDetails?.availableStatuses?.map((status, i) => {
                                            return (
                                                <Dropdown.Item onClick={() => handleStatus(status)} key={i} className={`text-capitalize text-${status?.variant_color}`}>
                                                    {status?.name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">
                                Historial de estados
                            </h5>
                        </div>
                        <div className="card-body custom-scrollbar scrollbar-primary" style={{ maxHeight: 420, overflowY: 'auto' }}>
                            <ul className="list-group">
                                {
                                    currentOrderDetails?.statuses?.map((orderStatus, i) => {
                                        return (
                                            <li className="list-group-item" key={i} style={{ borderBottomColor: orderStatus?.status?.color }}>
                                                <b>
                                                    {orderStatus?.createdAt}
                                                </b>
                                                <p>
                                                    El usuario <b> {orderStatus?.createdBy?.name}</b> ha marcado la orden como:
                                                    <b className={`text-capitalize text-${orderStatus?.status?.variant_color}`}> {orderStatus?.status?.name}</b>
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <CommentBox
                        commentableId={id}
                        commentableType="App\Models\LensOrder"
                    />
                </div>
            </div>
        </div>
    )
}

export default LensOrdersDetails;