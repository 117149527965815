import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import DateFormatter from "../../DateFormatter";
import { dateFine } from "../../../util/Utilities";

const EppOrdersColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Elaborado por',
        Component: ({ value }) => value?.createdBy?.name || '--'
    },
    {
        Label: () => 'Trabajador',
        Component: ({ value }) => value?.user?.name || '--'
    },
    {
        Label: () => 'Servicio',
        Component: ({ value }) => value?.service?.name || '--'
    },
    {
        Label: () => 'Estatus',
        Component: ({ value }) => <button className={`btn text-capitalize btn-${value?.status?.variant_color}`} type="button">
            {value?.status?.name}
        </button>
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default EppOrdersColumns;

